
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "LoadingComponent",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapState({
      isLoading: (state) => (state as any).isLoading,
    }),
  },
  watch: {},
});
