
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { LOADING_START, LOADING_STOP } from "@/store/action-types";
export default Vue.extend({
  mounted() {
    let fadeInTarget = document.querySelectorAll(".fadeIn");
    window.addEventListener("scroll", () => {
      for (let i = 0; i < fadeInTarget.length; i++) {
        const rect = fadeInTarget[i].getBoundingClientRect().top;
        const scroll = window.pageYOffset || document.documentElement.scrollTop;
        const offset = rect + scroll;
        const windowHeight = window.innerHeight; // 現在のブラウザの高さ
        if (scroll > offset - windowHeight + 150) {
          fadeInTarget[i].classList.add("scrollIn");
        }
      }
    });
    let fadeIn = document.querySelectorAll(".disp");
    window.addEventListener("scroll", () => {
      for (let i = 0; i < fadeIn.length; i++) {
        const rect = fadeIn[i].getBoundingClientRect().top;
        const scroll = window.pageYOffset || document.documentElement.scrollTop;
        const offset = rect + scroll;
        const windowHeight = window.innerHeight; // 現在のブラウザの高さ
        if (scroll > offset - windowHeight + 150) {
          fadeIn[i].classList.add("dispIn");
        }
      }
    });
  },
  computed: {
    ...mapState({
      isLoading: (state: any) => (state as any).isLoading,
    }),
  },
  methods: {
    ...mapActions({
      loadingStart: LOADING_START,
      loadingStop: LOADING_STOP,
    }),
  },
  async created() {
    const sleep = (ms: any) => new Promise((res) => setTimeout(res, ms));
    await Promise.all([
      await this.loadingStart(),
      await await sleep(2000),
      await this.loadingStop(),
    ]);
  },
  updated() {
    if (this.$route.path == "/") {
      let fadeInTarget = document.querySelectorAll(".fadeIn");
      window.addEventListener("scroll", () => {
        for (let i = 0; i < fadeInTarget.length; i++) {
          const rect = fadeInTarget[i].getBoundingClientRect().top;
          const scroll = window.pageYOffset || document.documentElement.scrollTop;
          const offset = rect + scroll;
          const windowHeight = window.innerHeight; // 現在のブラウザの高さ
          if (scroll > offset - windowHeight + 150) {
            fadeInTarget[i].classList.add("scrollIn");
          }
        }
      });
    }

  },
});
