
import Vue from "vue";


export default Vue.extend({
	name: "flowComponent",
	components: {
	},
	props: {},
	data() {
		return {};
	},
	methods: {},
	computed: {},
	watch: {},
});
