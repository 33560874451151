
import Vue from "vue";

export default Vue.extend({
  name: "HeaderComponent",
  components: {},
  props: {},
  data() {
    return {
    };
  },
  methods: {
    top() {
      this.$router.push({ name: 'home' })
    },
  },
  mounted() {
    window.addEventListener("scroll", this.getScroll);
  },
  computed: {},
  watch: {},
});
