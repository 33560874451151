
import Vue from "vue";

export default Vue.extend({
  name: "TopComponent",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
  watch: {},
});
