import { render, staticRenderFns } from "./PlanComponent.vue?vue&type=template&id=464ec8f8&scoped=true&"
import script from "./PlanComponent.vue?vue&type=script&lang=ts&"
export * from "./PlanComponent.vue?vue&type=script&lang=ts&"
import style0 from "./PlanComponent.vue?vue&type=style&index=0&id=464ec8f8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "464ec8f8",
  null
  
)

export default component.exports