import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import BtoCView from '../views/BtoCView.vue'
import BtoBView from '../views/BtoBView.vue'
import TokushoView from '../views/TokushoView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'r9uk0' }
  },
  {
    path: '/tutoring',
    name: 'BtoCLP',
    component: BtoCView,
    meta: { title: '個人向けプログラミングマンツーマン指導サービス' }

  },
  {
      path: '/tokusho',
      name: 'Tokusho',
      component: TokushoView,
      meta: { title: '特定商取引法に基づく表記' }
    },
  {
    path: '/corporate',
    name: 'BtoBLP',
    component: BtoBView
  }
]

const DEFAULT_TITLE = 'r9uk0'
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach((to, from) => {
  if(to.meta != undefined){
    document.title = to.meta.title || DEFAULT_TITLE
  }
})

export default router
