
import Vue from "vue";
import TopComponent from "@/components/Tokusho/TopComponent.vue";
import TokushoComponent from "@/components/Tokusho/TokushoComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import HeaderComponent from "@/components/Tokusho/HeaderComponent.vue";
export default Vue.extend({
  name: "HomeView",
  components: {
    TopComponent,
    TokushoComponent,
    FooterComponent,
    HeaderComponent,
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
});
