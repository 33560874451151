import { render, staticRenderFns } from "./CtaComponent.vue?vue&type=template&id=31c9d83c&scoped=true&"
import script from "./CtaComponent.vue?vue&type=script&lang=ts&"
export * from "./CtaComponent.vue?vue&type=script&lang=ts&"
import style0 from "./CtaComponent.vue?vue&type=style&index=0&id=31c9d83c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31c9d83c",
  null
  
)

export default component.exports