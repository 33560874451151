
import Vue from "vue";
import { Carousel, Slide } from 'vue-carousel';


export default Vue.extend({
	name: "VoiceComponent",
	components: {
		Slide,
		Carousel,
	},
	props: {},
	data() {
		return {};
	},
	methods: {},
	computed: {},
	watch: {},
});
