
import Vue from "vue";
import HeaderComponent from "@/components/BtoCLP/HeaderComponent.vue";
import TopComponent from "@/components/BtoCLP/TopComponent.vue";
import IssueComponent from "@/components/BtoCLP/IssueComponent.vue";
import MissionComponent from "@/components/BtoCLP/MissionComponent.vue";
import PlanComponent from "@/components/BtoCLP/PlanComponent.vue";
import VoiceComponent from "@/components/BtoCLP/VoiceComponent.vue";
import CtaComponent from "@/components/BtoCLP/CtaComponent.vue";
import FooterComponent from "@/components/BtoCLP/FooterComponent.vue";
import gsap from 'gsap';

export default Vue.extend({
  name: "BtoCView",
  components: {
    HeaderComponent,
    TopComponent,
    IssueComponent,
    MissionComponent,
    PlanComponent,
    VoiceComponent,
    CtaComponent,
    FooterComponent
  },
  data() {
    return {
      is01displayed: false,
      is02displayed: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      let fadeInTarget = document.querySelector(".js-countUp-trigger01");
      if (fadeInTarget == null) {
        return
      }
      const rect = fadeInTarget.getBoundingClientRect().top;
      const scroll = window.pageYOffset || document.documentElement.scrollTop;
      const offset = rect + scroll;
      const windowHeight = window.innerHeight; // 現在のブラウザの高さ
      if (scroll > offset - windowHeight + 150 && !this.is01displayed) {
        this.is01displayed = true;
        this.displayCountup(1);
      }
    });

    window.addEventListener("scroll", () => {
      let fadeInTarget = document.querySelector(".js-countUp-trigger02");
      if (fadeInTarget == null) {
        return
      }
      const rect = fadeInTarget.getBoundingClientRect().top;
      const scroll = window.pageYOffset || document.documentElement.scrollTop;
      const offset = rect + scroll;
      const windowHeight = window.innerHeight; // 現在のブラウザの高さ
      if (scroll > offset - windowHeight + 150 && !this.is02displayed) {
        this.is02displayed = true;
        this.displayCountup(2);
      }
    });

    let fadeInTarget = document.querySelectorAll(".fadeIn");
    let pattern6 = document.querySelectorAll(".pattern6");
    window.addEventListener("scroll", () => {
      for (let i = 0; i < fadeInTarget.length; i++) {
        const rect = fadeInTarget[i].getBoundingClientRect().top;
        const scroll = window.pageYOffset || document.documentElement.scrollTop;
        const offset = rect + scroll;
        const windowHeight = window.innerHeight; // 現在のブラウザの高さ
        if (scroll > offset - windowHeight + 150) {
          fadeInTarget[i].classList.add("scrollIn");
        }
      }

      for (let i = 0; i < pattern6.length; i++) {
        const rect = pattern6[i].getBoundingClientRect().top;
        const scroll = window.pageYOffset || document.documentElement.scrollTop;
        const offset = rect + scroll;
        const windowHeight = window.innerHeight; // 現在のブラウザの高さ
        if (scroll > offset - windowHeight + 150) {
          pattern6[i].classList.add("show");
        }
      }
    });
    document.addEventListener("DOMContentLoaded", () => {
      let pattern6 = document.querySelectorAll(".pattern6");
      for (let i = 0; i < pattern6.length; i++) {
        pattern6[i].classList.add("show");
      }
      let fadeInTarget = document.querySelectorAll(".disp");
      for (let i = 0; i < 2; i++) {
        fadeInTarget[i].classList.add("dispIn");
      }
    });
  },
  methods: {
    displayCountup(targetNum: number) {
      // カウントアップさせるターゲット
      const countUpTrigger: any = document.querySelector(".js-countUp-trigger0" + targetNum);
      const countUpTarget: any = document.querySelector(".js-countUp-target0" + targetNum);
      // 連想(辞書)配列の数値情報
      if (countUpTrigger == null || countUpTarget == null) {
        return
      }
      const elementDic = {
        from: countUpTarget.dataset.from,
        to: countUpTarget.dataset.to,
      };
      // 連想(辞書)配列 gsapで制御させたい要素
      const elementNum = { count: elementDic.from };
      gsap.to(elementNum, {
        count: elementDic.to, //カウントさせる数値(終着点)
        duration: 1, //カウントアップさせる秒数
        ease: "none", //デフォルトでpower(指数)がかかっている
        scrollTrigger: {
          trigger: countUpTrigger, // トリガーとなる要素の指定
          start: "center center", // トリガー位置の調整
          toggleActions: "play none none reverse", // 再度スクロールしても発火させる
          markers: true, // マーカーの表示
        },
        onUpdate: () => {
          countUpTarget.textContent = Math.floor(elementNum.count); // 数字が変わる度にDOM要素に代入
        },
      });
    }
  },
  watch: {},
});
