
import Vue from "vue";

export default Vue.extend({
	name: "PlanComponent",
	components: {},
	props: {},
	data() {
		return {};
	},
	methods: {},
	computed: {},
	watch: {},
});
