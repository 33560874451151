
import Vue from "vue";

export default Vue.extend({
  name: "HeaderComponent",
  components: {},
  props: {},
  data() {
    return {
      scrollY: 0,
    };
  },
  methods: {
    scroll(target: string) {
      const element = document.getElementById(target);
      if (element) {
        const position = element.getBoundingClientRect().top + this.scrollY;
        window.scrollTo({
          top: position,
          behavior: "smooth",
        });
      }
    },
    getScroll() {
      this.scrollY = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.getScroll);
  },
  computed: {},
  watch: {},
});
